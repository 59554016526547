// import 'airbnb-browser-shims';
// import 'datatables.net-bs4';
import 'jquery';
import 'popper.js';
import 'bootstrap';
import 'wowjs';
import 'simplelightbox';
import 'mdbootstrap/js/mdb';
import 'moment/moment';
import 'fullcalendar/dist/fullcalendar';
import 'fullcalendar/dist/locale-all';
import 'cookieconsent/build/cookieconsent.min';

window.cookieconsent.initialise({
  container: document.getElementById('content'),
  palette: {
    popup: { background: '#003f8a', text: '#fff', link: '#fff' },
    button: { background: '#001f3f', text: '#ffffff' },
  },
  revokable: false,
  law: {
    regionalLaw: false,
  },
  content: {
    header: 'Einsatz von Cookies',
    message: 'Wir verwenden Cookies, um diese Website optimal gestalten zu können.<br>Detaillierte Informationen und wie Sie Ihre Einwilligung jederzeit widerrufen können, erhalten Sie in unserer',
    dismiss: 'OK',
    link: 'Datenschutzerklärung.',
    href: 'home/datenschutz/',
    close: '&#x274c;',
    target: '_blank',
  },
});

function skedFullcalendar(sked) {
  const noTime = $.fullCalendar.moment(sked.data('date'));
  const csrfToken = sked.data('csrf');
  const baseLink = sked.data('link');

  sked = sked.fullCalendar({
    header: {
      left: 'prev,next today',
      center: 'title',
      right: 'month,agendaWeek,listFourMonth',
    },
    plugins: ['bootstrap'],
    themeSystem: 'bootstrap4',
    views: {
      listFourMonth: {
        type: 'list',
        duration: { month: 1 },
        buttonText: 'Terminübersicht',
      },
    },
    locale: 'de',
    weekNumbers: true,
    weekNumbersWithinDays: true,
    defaultDate: noTime,
    dragScroll: true,
    eventLimit: true, // allow "more" link when too many events
    eventDrop(event, delta, revertFunc) {
    },
    eventResize(event, delta, revertFunc) {
    },
    eventClick(calEvent, jsEvent, view) {
      window.location.replace(`${baseLink}?event_id=${calEvent.id}`);
    },
    events: {
      url: `/index.php?rex-api-call=sked_exchange&_csrfToken=${csrfToken}`,
      cache: true,
      error(xhr, type, exception) {
        console.log(`Error: ${exception}`);
      },
      success(doc) {
        // add plus circle
      },
    },
  });
}

function skedInit() {
  const sked = $('#sked');

  if (sked.length) {
    skedFullcalendar(sked);
  }
}

$(window).bind('load', () => {
  let footerHeight = 0;
  let footerTop = 0;
  const $footer = $('.page-footer');

  positionFooter();

  function positionFooter() {
    footerHeight = $footer.height();
    footerTop = `${$(window).scrollTop() + $(window).height() - footerHeight}px`;

    if (($(document.body).height() + footerHeight) < $(window).height()) {
      $footer.addClass('sticky');
    } else {
      $footer.removeClass('sticky');
    }
  }

  $(window).resize(positionFooter);
});

$(document).ready(() => {
  $('a').each(function () {
    if ($(this).attr('target') === '_blank') {
      $(this).attr('rel', 'noopener noreferrer');
    }
  });
  $('.navbar-nav mr-auto a').addClass('nav-link');
  $('.fc-button').addClass('btn').addClass('btn-purple');

  $('.fc-prev-button').append('<i class="fas fa-angle-left"></i>');
  $('.fc-next-button').append('<i class="fas fa-angle-right"></i>');

  skedInit();

  $('#mainNavi a.fl-hasdrop').click(function (e) {
    e.preventDefault();
    const r = $(this).attr('href');
    if ($(this).hasClass('clicked')) {
      location.href = r;
    } else {
      $('#mainNavi a.first-level').removeClass('clicked');
      $(this).addClass('clicked');
    }
  });
  if ($('a.simplelight').length) {
    const gallery = $('a.simplelight').simpleLightbox({
      widthRatio: 0.96,
      animationSlide: true,
      spinner: false,
      disableScroll: false,
    });

    $('.simplelight').on('show.simplelightbox', (e) => {
      e.preventDefault();
      // $('body').addClass('noscroll');
    });
    $('.simplelight').on('closed.simplelightbox', (e) => {
      e.preventDefault();
      // $('body').removeClass('noscroll');
    });
  }
});

document.addEventListener('DOMContentLoaded', () => {
  const images = document.querySelectorAll('.lazy');
  const lazyBackgrounds = [].slice.call(document.querySelectorAll('.lazy-background'));

  if (!('IntersectionObserver' in window)) {
    images.forEach((image) => {
      handleImage(image);
    });
    $('.lazy-background').each(function () {
      const s = $(this).attr('style');
      const y = s.replace('_preload', '');
      $(this).attr('style', y);
    });
  } else {
    // BG
    const lazyBackgroundObserver = new IntersectionObserver(((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          const x = entry.target.style.backgroundImage;
          const y = x.replace('_preload', '');
          entry.target.style.backgroundImage = y;

          lazyBackgroundObserver.unobserve(entry.target);
        }
      });
    }));

    lazyBackgrounds.forEach((lazyBackground) => {
      lazyBackgroundObserver.observe(lazyBackground);
    });

    const config = {
      rootMargin: '100px 0px',
      threshold: 0.01,
    };
    const observer = new IntersectionObserver(observeImages, config);

    images.forEach((image) => {
      observer.observe(image);
    });

    function observeImages(entries) {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          observer.unobserve(entry.target);
          handleImage(entry.target);
        }
      });
    }
  }
});
